import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ISuccessDialogProps { leading: string, trailing?: string }

@Component({
  selector: 'app-success-dialog',
  standalone: true,
  imports: [],
  templateUrl: './success-dialog.component.html',
  styleUrl: './success-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SuccessDialogComponent implements OnInit, OnDestroy {

  timer!: ReturnType<typeof setTimeout>;

  constructor(
    private dialogRef: MatDialogRef<SuccessDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: ISuccessDialogProps 
  ) { }

  ngOnInit(): void {
    this.timer = setTimeout(() => {
      this.dialogRef.close();
    }, 1400)
  }

  ngOnDestroy(): void {
    clearTimeout(this.timer);
  }

}